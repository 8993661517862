import { useState } from "react";
import imgLogo from "../img/로고.png";
import "../styles/pages/login/login.css";
import script from "../scripts/login.json";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import PageLayout from "../components/PageLayout"
export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [wrong, setWrong] = useState(false);
  const navigate = useNavigate();

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      submitForm(e);
    }
  }

  return (
    <PageLayout>
    <div className="h-screen w-screen bg-gradient-to-r from-blue-500 to-blue-200 overflow-x-hidden">
      {/* <div className="login__container">
        <div className="main__container">
          <Link to={"/"} style={{ textDecoration: "none" }}>
            <div className="logo__container">
              <img src={logo} alt="" />
              <p className="brand">incento</p>
            </div>
          </Link>

          <div className="inputs">
            <div className="email__field field">
              <label htmlFor="username">{script.fields[0][0]}</label>
              <input
                onKeyDown={handleKeyDown}
                type="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                name="username"
                id="username"
                placeholder={script.fields[0][1]}
              />
            </div>
            <div className="password__field field">
              <label htmlFor="password">{script.fields[1][0]}</label>
              <input
                onKeyDown={handleKeyDown}
                type={type}
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                id="password"
                placeholder={script.fields[1][1]}
              />

              {type === "password" ? (
                password.length > 0 ? (
                  <AiOutlineEyeInvisible
                    className="eye__icon"
                    style={{ color: "#9ec2fd", fontSize: "20px" }}
                    onClick={(e) => {
                      setType("text");
                    }}
                  />
                ) : (
                  <></>
                )
              ) : password.length > 0 ? (
                <AiOutlineEye
                  className="eye__icon"
                  style={{ color: "#9ec2fd", fontSize: "20px" }}
                  onClick={(e) => {
                    setType("password");
                  }}
                />
              ) : (
                <></>
              )}
            </div>
            {wrong && <p className="wrong__text">{script.wrong}</p>}
            <a className="find__data" href="/login">
              아이디/비밀번호 찾기 <img src={arrowWhite} alt="" />
            </a>
          </div>

          <button className="login__btn" onClick={submitForm}>
            로그인
          </button>
          <div className="login__footer">
            <p className="left">{script.footer.left}</p>
            <Link to="/register" style={{ textDecoration: "none" }}>
              <p className="right">
                {script.footer.right} <img src={arrowBlack} alt="" />
              </p>
            </Link>
          </div>
        </div>
      </div> */}
      <div className="flex justify-center items-center w-full h-full  ">
        <div className="flex flex-col justify-center items-center w-full ">
          <div className="w-full flex justify-center items-center">
            <Link to={"/"}>
              <img src={imgLogo} alt="Logo" />
            </Link>
          </div>
          <div className="visible lg:invisible font-bold mt-6 text-gray-800">로그인은 PC환경에서 이용해주세요 </div>
          <div className="invisible lg:visible w-full max-w-[23rem] mt-5">
            <div className="block p-6  bg-transparent ">
              <div>
                <div className=" mb-6">
                  <input
                    onKeyDown={handleKeyDown}
                    type="username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    name="username"
                    id="username"
                    placeholder={script.fields[0][1]}
                    className="block  border border-solid border-gray-300 bg-white  w-full  px-3 py-2.5  text-base font-normal text-gray-800 rounded-lg focus:outline-none transition ease-in-out"
                  />
                </div>
                <div className=" mb-6 relative">
                  <input
                    onKeyDown={handleKeyDown}
                    type={type}
                    name="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    id="password"
                    placeholder={script.fields[1][1]}
                    className=" block w-full px-3 border border-solid border-gray-300 py-2.5 text-base focus:outline-none font-normal text-gray-800 bg-white rounded-lg transition ease-in-out"
                  />

                  <div className="absolute right-3 top-3">
                    {type === "password" ? (
                      password.length > 0 ? (
                        <AiOutlineEyeInvisible
                          className="text-[#8f909c] text-2xl"
                          onClick={(e) => {
                            setType("text");
                          }}
                        />
                      ) : (
                        <></>
                      )
                    ) : password.length > 0 ? (
                      <AiOutlineEye
                        className="text-[#8f909c] text-2xl"
                        onClick={(e) => {
                          setType("password");
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {wrong && <p className="wrong__text">{script.wrong}</p>}

                <button
                  onClick={submitForm}
                  className=" font-bold w-full px-6 py-2.5  text-md transition  duration-150
				   ease-in-out text-white bg-blue-500 rounded-md shadow hover:bg-gray-100 hover:text-gray-800"
                >
                  로그인
                </button>
              </div>
            </div>
            <div className="flex justify-center items-center w-full ">
              <div className="px-3">
                <a
                  href="https://incento-app.notion.site/Incento-Signup-Guide-13dbc7d6aa814dfdae3cdef2f886f127"
                  target="_blank"
                  rel="noreferrer"
                  className="appearance-none text-gray-800 font-bold"
                >
                  {script.footer.right}
                </a>
              </div>

              {<div className="px-3">
                <Link
                  to="/forgot"
                  className="appearance-none text-gray-800 font-bold"
                >
                  비밀번호 찾기
                </Link>
              </div>}
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </PageLayout>
  );

  function submitForm(e: any) {
    if (!username || !password) {
      alert("비밀번호랑 아이디를 입력해주세요");
    } else {
      let backend =
        process.env.REACT_APP_SERVER_BASE_PATH + "account/login-merchant/";
      axios
        .post(backend, { username, password })
        .then((response) => {
          document.cookie = `access=${response.data.access};domain=${process.env.NODE_ENV === 'production' ? '.incento.kr' : 'localhost'}`
          document.cookie = `refresh=${response.data.refresh};domain=${process.env.NODE_ENV === 'production' ? '.incento.kr' : 'localhost'}`
          navigate("/select");
        })
        .catch((err) => {
          if (err.response.status === 400) {
            alert("아이디랑 비밀번호를 확인해주세요");
          }
        });
    }
  }
}
