import React, { useRef, useState } from "react";
import logo from "../Assets/img-logo.png";
import "../styles/pages/forgot/reset.css";
import script from "../scripts/login.json";
import arrowWhite from "../Assets/arrow_blue.svg";
import arrowBlack from "../Assets/ic-arrow-right.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

export default function ResetPassword() {
    const [confirm, setConfirm] = useState("");
    const [password, setPassword] = useState("");
    const [type, setType] = useState("password");
    const [after, setAfter] = useState(false);
    const [error, setError] = useState(false);
    const [passwordError, setPasswordError] = useState("사용 불가능"); // To track password validity
    const [instantPWChk, setInstantPWChk] = useState(false); // To track password match
    const [showPW, setShowPw] = useState(false); // Toggle visibility of password
    const navigate = useNavigate();
    let { token } = useParams();

    function handleKeyDown(e: any) {
        if (e.key === "Enter") {
            submitForm(e);
        }
    }

    function handleNewPasswordChange(e: any) {
        const pw = e.target.value;
        setPassword(pw);

        // Check password requirements: At least 8 characters, 1 uppercase, 1 special character
        const isValid = pw.length >= 8 && /[A-Z]/.test(pw) && /[!@#$%^&*(),.?":{}|<>]/.test(pw);

        if (isValid) {
            setPasswordError("사용 가능");
        } else {
            setPasswordError("사용 불가능");
        }
    }

    function handleNewPasswordChkChange(e: any) {
        const confirmPw = e.target.value;
        setConfirm(confirmPw);

        // Check if passwords match
        setInstantPWChk(password === confirmPw);
    }

    function submitForm(e: any) {
        if (!confirm || !password) {
            alert("모든 항목을 입력해주세요");
        } else if (confirm !== password) {
            alert("비밀번호가 일치하지 않습니다");
        } else if (password.length < 8) {
            alert("비밀번호는 8자 이상이어야 합니다");
        } else if (/^\d+$/.test(password)) {
            alert("비밀번호는 숫자만으로 이루어질 수 없습니다");
        } else {
            let backend =
                process.env.REACT_APP_SERVER_BASE_PATH +
                "account/password_reset/confirm/";
            let data = {
                password: password,
                token: token,
            };
            axios
                .post(backend, data)
                .then((response) => {
                    setAfter(true);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 400) {
                        let messages = err.response.data["password"];
                        for (let i = 0; i < messages.length; i++) {
                            let message = messages[i];
                            if (message === "This password is too common.") {
                                alert("비밀번호가 너무 흔합니다. 다른 비밀번호를 사용해주세요");
                                return;
                            }
                            if (message === "The password is too similar to the username.") {
                                alert("비밀번호가 아이디와 너무 유사합니다. 다른 비밀번호를 사용해주세요");
                                return;
                            }
                            if (message === "The password is too similar to the email.") {
                                alert("비밀번호가 이메일과 너무 유사합니다. 다른 비밀번호를 사용해주세요");
                                return;
                            }
                        }
                        alert("인센토 팀으로 문의를 남겨주세요");
                    }
                    if (err.response.status === 404) {
                        alert("비밀번호 재설정 링크가 만료되었습니다. 다시 시도해주세요");
                        return;
                    }
                    setAfter(true);
                    setError(true);
                });
        }
    }

    return (
        <div className="forgot__container">
            <div className="main__container">
                <Link to={"/"} style={{ textDecoration: "none" }}>
                    <div className="logo__container">
                        <img src={logo} alt="" />
                    </div>
                </Link>
            </div>
            {after ? (
                error ? (
                    <div className="inputs">
                        <label htmlFor="password">
                            비밀 설정 중에 오류가 생겼습니다. 오류가 계속 생기면 인센토 팀에게
                            문의하세요.{" "}
                        </label>
                        <Link to="/forgot">
                            <button className="login__btn">다시하기</button>
                        </Link>
                    </div>
                ) : (
                    <div className="inputs">
                        <label htmlFor="password">비밀번호가 재설정되었습니다 </label>
                        <Link to="/login">
                            <button className="login__btn">로그인하기</button>
                        </Link>
                    </div>
                )
            ) : (
                <div className="inputs">
                    <label htmlFor="password">비밀번호를 재설정합니다</label>

                    {/* New Password Input with requirements */}
                    <div className="password__field field">
                        <input
                            onKeyDown={handleKeyDown}
                            type={showPW ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={handleNewPasswordChange}
                            id="password"
                            placeholder={script.fields[1][1]}
                        />
                        <button className="eye__icon" onClick={() => setShowPw(!showPW)}>
                            {showPW ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </button>
                    </div>

                    {/* Password requirements and validation */}
                    <div className="flex justify-between w-full text-xs text-left"
                         style={{marginTop: '-16px', marginBottom: '4px'}}>
                        <p className="text-gray-500">*비밀번호는 최소 8자, 대문자, 특수기호 포함</p>
                        <span style={passwordError === "사용 불가능" ? {color: "red"} : {color: "green"}}>
                            [{passwordError}]</span>
                    </div>

                    {/* Confirm Password Input */}
                    <div className="password__field field">
                        <input
                            onKeyDown={handleKeyDown}
                            type={showPW ? "text" : "password"}
                            name="confirm"
                            value={confirm}
                            onChange={handleNewPasswordChkChange}
                            id="confirm"
                            placeholder={"비밀번호를 다시 한번 입력해주세요"}
                        />
                        <button className="eye__icon" onClick={() => setShowPw(!showPW)}>
                            {showPW ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                        </button>
                    </div>

                    {/* Password match validation */}
                    <div className="w-full text-xs text-right" style={{ marginTop: '-16px' }}>
                        <span style={{ color: instantPWChk ? "green" : "red" }}>
                            [{instantPWChk ? "일치" : "불일치"}]
                        </span>
                    </div>

                    {/* Submit Button */}
                    <button className="login__btn" onClick={submitForm}>
                        재설정하기
                    </button>
                </div>
            )}
        </div>
    );
}
